import { useMatches } from '@remix-run/react'

import { User } from '@rooquest/common'

export default function useCurrentUser(): User | undefined {
  const matches = useMatches()
  let currentUser: User | undefined

  for (const route of matches) {
    if ((route.data as any)?.$currentUser) {
      currentUser = (route.data as any).$currentUser
      break
    }
  }

  return currentUser
}
